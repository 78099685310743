<template>
  <div class="">
    <div class="payApplyTitle">
      <van-nav-bar fixed title="报销申请" left-arrow @click-left="onClickLeft" />
    </div>
    <div class="payMain">
      <!-- 发起人信息 -->
      <van-form validate-trigger="onSubmit" colon ref='form'>
        <div class="personTitle titleA">发起人信息</div>
        <van-field label="申请人" v-model="listPayUser.sponsorName" name="sponsorName" readonly />
        <van-field label="所属部门" is-link readonly :value="listPayUser.sponsorDep" placeholder="请选择所属部门" name="depName"
          @click="handlePicker(1)" :rules="[{
            required: true,
            message: '',
          }]" :required="true" />
        <van-field label="所属月份" is-link readonly :required="true" :value="listPayUser.billMonth" name="billMonth"
          placeholder="请选择月份" @click="monthShow = true" :rules="[{
            required: true,
            message: '',
          }]" />
        <van-field name="shareType" label="是否分摊" :value="listPayUser.shareType">
          <template #input>
            <van-radio-group v-model="listPayUser.shareType" direction="horizontal">
              <van-radio name='1'> 是</van-radio>
              <van-radio name='0'>否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field label="所属客户" readonly is-link name="customerId" :value="listPayUser.customerName" placeholder="请选择"
          @click="handlePicker(2)" @change="changes(e, a)" />
        <van-collapse v-model="activeNames" v-if="listPayUser.shareType == 1">
          <van-collapse-item title="已选择客户" name="1">
            <van-list>
              <van-cell v-for="item in customers" :key="item.customerId" :title="item.customerName" />
            </van-list>
          </van-collapse-item>
        </van-collapse>
        <div class="detailTitle titleA">报销明细</div>
        <div>
          <div class="ejxpDetails" v-for="(itemD, ind) of listPay" :key="ind">
            <div class="expDetail" v-if="itemD.delFile == 0 || itemD.delFile == undefined">
              <div class="expDetailTitle">
                <div class="expDetailOrd">{{ ind + 1 }}</div>
                <div class="delete" @click="expDelete(itemD, ind)">删除</div>
              </div>
              <div class="detailMain">
                <van-field label="所属日期" readonly is-link name="makeDay" :required="true" :value="listPay[ind].makeDay"
                  placeholder="请选择日期" @click="dayChoice(ind)" :rules="[{
                    required: true,
                    message: '',
                  }]" />
                <van-field label="费用类型" readonly is-link name="feeTypeCollect" :required="true"
                  :value="listPay[ind].feeTypeCollect" placeholder="请选择" @click="handlePickerType(ind)" :rules="[{
                    required: true,
                    message: '',
                  }]" />
                <van-field label="金额" clearable :required="true" type="number" v-model="listPay[ind].amount"
                  placeholder="请输入报销金额" :rules="[{
                    required: true,
                    message: '',
                  }]" />
                <van-field label="费用说明" clearable autosize v-model="listPay[ind].remark" :required="true"
                  placeholder="请输入费用说明" maxlength="150" type="textarea" show-word-limit :rules="[{
                    required: true,
                    message: '',
                  }]" />
              </div>
            </div>
          </div>
          <div class="newDetail" @click="newDetail">
            新增款项
          </div>
          <van-field label="发票" :required="true" class="uploaderField" :rules="[{
            required: true,
            message: '',
          }]" name="uploader">
            <template #input>
              <van-uploader v-model="fileFormat" :after-read="afterRead" @delete="deleteA" :max-size="3072 * 1024"
                accept="image/*" :max-count="20" deletable show-upload>
              </van-uploader>
            </template>
          </van-field>
          <div class="tips">
            不可用发票：固定资产（电脑、打印机、空调、电视、家具等大件物品）、服装、化妆品、奢侈品、食物（菜、零食、饮料等）、烟酒、礼品类
          </div>
        </div>
      </van-form>
      <div class="emptyP"></div>
      <!-- 提交提示和历史记录按钮 -->
      <div class="payButton">
        <!-- <button type="submit" @click="dialogClick" class="submitBtn"> <img src="../../assets/submitIcon.svg" alt="">提交</button> -->
        <!-- <button type="submit" class="historyBtn" @click="viewHistory"><img src="../../assets/history.svg" alt="">历史记录</button> -->
        <div class="submitBtn" @click="dialogClick" native-type="submit">
          <img src="../../assets/submitIcon.svg" alt="">
          <div>提交</div>
        </div>
        <div class="historyBtn" @click="viewHistory">
          <img src="../../assets/history.svg" alt="">
          <div>历史记录</div>
        </div>
      </div>
    </div>
    <div class="vantList">
      <!-- 发生日期选择 -->
      <!-- <van-popup v-model="dayShow" position="bottom" overlay-class="popupDay">
          <div class="dayTop">
            <div class="dayLeft">
              <div class="cancelPop" @click="cancelClickD">取消</div>
              <div class="emptyPop" @click="emptyClickD">清空</div>
            </div>
            <div class="confirmPop" @click="confirmClickD">确定</div>
          </div>
          <van-datetime-picker
            v-model="currentDateD"
            type="date"
            :show-toolbar="false"
            :min-date="minDateD"
            :max-date="maxDateD"
          />
        </van-popup> -->
      <!-- 日期范围选择 -->
      <van-calendar v-model="dayShow" :min-date="minDateD" :max-date="maxDateD" type="range" color="#FD5F0F"
        allow-same-day row-height="60" :show-title="false" @confirm="confirmClickD" />

      <!-- 所属月份选择 -->
      <van-popup v-model="monthShow" position="bottom" overlay-class="popupMonth">
        <div class="monthTop">
          <div class="monthLeft">
            <div class="cancelPop" @click="cancelClickM">取消</div>
            <div class="emptyPop" @click="emptyClickM">清空</div>
          </div>
          <div class="confirmPop" @click="confirmClickM">确定</div>
        </div>
        <van-datetime-picker v-model="currentDateM" type="year-month" :show-toolbar="false" :min-date="minDateM"
          :max-date="maxDateM" :formatter="formatterM" />
      </van-popup>
      <!-- 选择器 -->
      <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
      </van-popup>
      <!-- 级联选择费用类型 -->
      <van-popup v-model="showPickerType" position="bottom">
        <van-picker show-toolbar :columns="columnType" @confirm="onConfirmType" @cancel="showPickerType = false" />
      </van-popup>
      <!-- 点击提交以后的弹出框 -->
      <van-dialog v-model="showDialog" show-confirm-button show-cancel-button confirm-button-text="确认提交"
        cancel-button-text="再看看" confirm-button-color="#0E6CCB" @confirm="submitClick">
        <div class="titleDia">提示</div>
        <div class="mainDia">
          共计【{{ listPay.length }}】项报销明细，<br>
          费用相关部门有【{{ listPayUser.sponsorDep }}】
        </div>
        <div class="moneyDia">合计金额：￥{{ moneyTotle }}</div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import "../../styles/reimburse.css";
import sendWx from '../../utils/wxconfig';
import moment from "moment";
import OSS from 'ali-oss';
import ossConfig from '../../utils/ossConfig';
import { reimburseBaseInfo, reimburseCustomerList, reimburseDepartmentList, reimburseFeeTypeList, reimburseGetUrl, reimburseSubmit, reimburseDetail } from '../../api/reimburse';
import { NavBar, Dialog, DatetimePicker, Calendar, Popup, Form, Field, Toast, Uploader, Overlay, Button, Radio, Picker, Grid, GridItem, RadioGroup, Collapse, CollapseItem, List, Cell } from 'vant';
Vue.use(NavBar).use(DatetimePicker).use(Dialog).use(Calendar).use(Popup).use(RadioGroup).use(Toast).use(Form).use(Overlay).use(Uploader)
  .use(Field).use(Button).use(Radio).use(Picker).use(Grid).use(GridItem).use(Collapse).use(CollapseItem).use(List).use(Cell);

export default {
  name: 'reimburse',
  data() {
    return {
      value: '',
      activeNames: ['1'],
      showDialog: false, //弹出框
      monthShow: false,//月份选择
      minDateM: new Date(2021, 0, 1),
      maxDateM: new Date(2122, 10, 1),
      currentDateM: new Date(),
      dayShow: false,//日期选择
      minDateD: new Date(2021, 0, 1),
      maxDateD: new Date(2122, 10, 1),
      currentDateD: new Date(),
      shareType: 1,
      activeReimValue: '',
      customers: [],
      listPayUser: {  // 申请人信息
        reimId: '',
        sponsorName: '',
        sponsorDepId: '',
        sponsorDep: '',
        billMonth: '',
        customerId: '',
        customerName: '',
        details: [],
        customers: [],
        draft: '',//1-草稿，不填或其他都是审批中
        invoices: [],//上传发票的参数
      },
      listPay: [
        {
          detailId: '',
          makeDay: '',
          makeDate: '',
          makeDateEnd: '',
          feeTypeCollect: '',//11
          feeType: '',//费用类型第一级
          invCategory: '',//发票类目第二级
          subdivision: '', //细分第三级
          amount: '',
          remark: '',
          delFile: 0, //删除明细,编辑用
        }
      ],
      listPayNew: [],//上传的参数
      //发票列表id
      fileListId: [],
      // 选择器变量
      itemValueP: '',
      activeValuePick: "",
      activeValuePickId: "",
      activeReimValueType: "",
      // 选择器
      showPicker: false,
      showPickerType: false,
      columns: [],
      depList: [],
      columnType: [{
        text: '差旅费',
        children: [
          { text: '交通费', children: [{ text: '高铁票' }, { text: '机票' }, { text: '公交票' }, { text: '地铁票' }, { text: '出租车票' }] },
          { text: '住宿票', children: [{ text: '' }] },
          { text: '餐饮票', children: [{ text: '' }] },
        ]
      },
      {
        text: '业务招待费',
        children: [{ text: '餐饮票', children: [{ text: '' }] }]
      },
      {
        text: '汽车使用费',
        children: [{ text: '过桥过路费', children: [{ text: '' }] }, { text: '加油费', children: [{ text: '' }] }, { text: '停车费', children: [{ text: '' }] },
        { text: '汽车修理费', children: [{ text: '' }] }, { text: '汽车保养费', children: [{ text: '' }] }, { text: '洗车费', children: [{ text: '' }] }]
      },
      {
        text: '租赁费',
        children: [{ text: '租车费用', children: [{ text: '' }] }, { text: '房租费', children: [{ text: '' }] }]
      },
      {
        text: '办公费',
        children: [{ text: '办公用品', children: [{ text: '' }] }, { text: '快递费', children: [{ text: '' }] }, { text: '维修费', children: [{ text: '' }] },
        { text: '软件认证费', children: [{ text: '' }] }, { text: '水电费', children: [{ text: '' }] }, { text: '物业费', children: [{ text: '' }] }]
      },
      {
        text: '招聘费',
        children: [{ text: '招聘费', children: [{ text: '' }] }]
      },
      {
        text: '培训费',
        children: [{ text: '培训费', children: [{ text: '' }] }]
      },
      {
        text: '运杂费',
        children: [{ text: '装修费', children: [{ text: '砸墙' }, { text: '垃圾清运' }, { text: '其他' }] },
        ]
      },
      {
        text: '福利费',
        children: [{ text: '团建费用', children: [{ text: '' }] }, { text: '核酸检测', children: [{ text: '' }] }]
      }
      ],//费用类型列表
      columnsCust: [],//所属客户
      indexUp: 0,
      fileFormat: [],  //文件
      IdReim: '',
      clientOSS: '',
      deleteList: [],
    };
  },
  created() {
  },
  mounted() {
    // 查看是编辑还是新建
    this.IdReim = this.$route.query.reimId
    this.clientOSS = new OSS(ossConfig);

    if (this.IdReim) {
      this.getUserInfo();
      this.getDetail();
    } else {
      this.getUserInfo(); //先获取个人信息
    }

  },
  // 报销明细总金额
  computed: {
    moneyTotle: {
      get: function () {
        let result = "";
        this.listPay.forEach(item => {
          let comItem = item.amount;
          result += Number(comItem);
          result = Number(result)
        })
        return result
      },
      set: function (newResult) {
        this.moneyTotle = newResult
      }
    }
  },
  methods: {
    changes(e, a) { // select确定,
      console.log(e, a)
    },
    // 获取用户信息
    getUserInfo() {
      reimburseBaseInfo().then(res => {
        this.listPayUser.sponsorName = res.resultData.sponsorName
        this.listPayUser.sponsorId = res.resultData.sponsorId
        res.resultData.depList.forEach(item => {
          this.depList.push({
            text: item.depName,
            key: item.depId
          })
        })
      })
    },
    // 编辑详情
    getDetail() {
      reimburseDetail({ reimId: this.IdReim }).then(res => {
        console.log(res)
        this.listPayUser = res.resultData
        res.resultData.invoices.forEach(item => {
          this.fileFormat.push({ url: item.fileUrl })
        })
        this.listPay = res.resultData.details
        this.listPay.forEach(item => {
          item.makeDay = `${item.makeDate} 至 ${item.makeDateEnd}`;
        })
        console.log(this.listPay)
      })
    },
    // 点击出现弹出框
    dialogClick() {
      if (this.listPay.length === 0) {
        Toast('请先添加报销明细');
        return
      }
      this.$refs.form.validate().then(() => {
        this.showDialog = true
      }).catch(() => {
        this.$toast.fail('必传项不可为空')
      })
    },
    // 弹出框里点击提交
    submitClick() {
      let list = []
      if (this.deleteList.length == 0) {
        list = this.listPay
      } else {
        list = this.deleteList.concat(this.listPay)
      }
      const params = {
        reimId: this.IdReim || '',
        sponsorDepId: this.listPayUser.sponsorDepId,
        billMonth: this.listPayUser.billMonth,
        customerId: this.listPayUser.customerId,
        draft: this.listPayUser.draft,
        details: list,
        invoices: this.listPayUser.invoices || [],
        customers: [],
        shareType: this.listPayUser.shareType

      }
      console.log(params)
      let arrs = []
      if (params.shareType == 0) {
        let obj = { customerId: params.customerId };
        arrs.push(obj)
        params.customers = arrs
      } else {
        params.customers = this.customers
      }
      reimburseSubmit(params).then(res => {
        console.log(res)
        let urlW = window.location.href;
        // console.log(urlW)
        let indexUrl = urlW.indexOf('#');
        // console.log(indexUrl)
        let urlWx = urlW.substring(0, indexUrl)
        console.log(urlWx)
        sendWx(
          urlWx,
          {
            fieldList: [
              {
                title: '请款类型',
                type: 'text',
                value: '报销申请',
              },
              {
                title: '支出总额',
                type: 'text',
                value: `${this.moneyTotle}`,
              },
              {
                title: '报销详情',
                type: 'link',
                value: `${window.location.origin}/#/viewReim?reimId=${res.resultData.reimId}`,
              },
            ],
          },
          res.resultData.billNo,
          () => {
            this.$toast.success('提交成功')
            this.$router.replace('/historyReim')
          },
          3,
        );
      }).catch(err => {
        console.log(err)
        Toast(err.resultMsg);
      }
      )
    },
    // 所属客户列表
    getListCust() {
      reimburseCustomerList().then(res => {
        res.resultData.forEach(item => {
          this.columnsCust.push({
            text: item.customerName,
            key: item.customerId
          })
        })
        this.columns = this.columnsCust
      })
    },
    formatterM(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    //所属月份选择
    formatDateTimeM(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      return y + '-' + m
    },
    cancelClickM() {
      this.monthShow = false;
    },
    emptyClickM() {
      this.monthShow = false;
      this.listPayUser.billMonth = "";
    },
    confirmClickM() {
      console.log(22222222222222)
      this.listPayUser.billMonth = this.formatDateTimeM(this.currentDateM);
      this.monthShow = false;
      //更改日期选择的最大最小值
      this.listPay.forEach(item => {
        item.makeDate = ''
      })
      let year = this.currentDateM.getFullYear();
      let min = this.currentDateM.getMonth() + 1;
      var day = 31
      if (min == 1 || min == 3 || min == 5 || min == 7 || min == 8 || min == 10 || min == 12) {
        day = 31
      } else if (min == 4 || min == 6 || min == 9 || min == 11) {
        day = 30
      } else if (min == 2) {
        if (year % 4 == 0 && !(year % 100 == 0) || year % 400 == 0) {
          day = 29
        } else {
          day = 28
        }
      }
      min = min - 1
      min = min < 10 ? ('0' + min) : min;
      this.minDateD = new Date(year, min, 1)
      this.maxDateD = new Date(year, min, day)
    },
    // 发生日期选择
    dayChoice(indexD) {
      this.activeReimValue = indexD;
      this.dayShow = true;
    },
    // 日期格式
    formatDateTimeD(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d
    },
    cancelClickD() {
      this.dayShow = false;
    },
    emptyClickD() {
      this.dayShow = false;
      this.listPay[this.activeReimValue].makeDate = "";
    },
    confirmClickD(date) {
      const [start, end] = date;
      this.listPay[this.activeReimValue].makeDay = `${this.formatDateTimeD(start)} 至 ${this.formatDateTimeD(end)}`;
      this.listPay[this.activeReimValue].makeDate = this.formatDateTimeD(start);
      this.listPay[this.activeReimValue].makeDateEnd = this.formatDateTimeD(end);
      // this.listPay[this.activeReimValue].makeDate = this.formatDateTimeD(this.currentDateD);
      this.dayShow = false;
    },
    // 单项选择器
    handlePicker(itemValueP) {
      this.columns = []
      this.showPicker = true;
      if (itemValueP == '1') {
        this.activeValuePickId = 'sponsorDepId'
        this.activeValuePick = 'sponsorDep'
        this.columns = this.depList
      } else if (itemValueP == '2') {
        this.activeValuePickId = 'customerId'
        this.activeValuePick = 'customerName'
        this.getListCust()
      }
    },
    onConfirm(value) {
      this.listPayUser[this.activeValuePick] = value.text;
      this.listPayUser[this.activeValuePickId] = value.key;
      this.showPicker = false;
      let obj = {
        customerName: value.text,
        customerId: value.key,
      }
      if (this.activeValuePickId === 'customerId') {
        let arr = [...this.customers];
        arr.push(obj)
        this.customers = arr.filter((currentValue, currentIndex, selfArr) => {
          return selfArr.findIndex(x => x.customerId === currentValue.customerId) === currentIndex
        });
      }
    },
    // 级联选择
    handlePickerType(indexT) {
      this.activeReimValueType = indexT;
      this.showPickerType = true;
    },
    onConfirmType(value) {
      this.listPay[this.activeReimValueType].feeType = value[0];
      this.listPay[this.activeReimValueType].invCategory = value[1];
      this.listPay[this.activeReimValueType].subdivision = value[2];
      this.showPickerType = false;
      if (value[2] == '') {
        value.pop()
      }
      this.listPay[this.activeReimValueType].feeTypeCollect = value.toString();

    },
    afterRead(file) { //上传封面图片到ali-oss
      const fileN = file.content;
      const fileU = file.file.name;
      const base64 = fileN.split(',').pop();
      const fileType = fileN.split(';').shift().split(':').pop();
      const blob = this.toBlob(base64, fileType)
      this.readFile(blob).then(res => {
        let d1 = moment().format('YYYY/MM/DD')
        let d2 = moment().format('YYYYMMDDHHmmss')
        let mark = `erp/${d1}/reimInvoice_${d2}_${file.file.name}`;
        const buffer = new OSS.Buffer(res.target.result);
        this.clientOSS.put(mark, buffer).then(result => {
          this.imageUrl = result.url;
          console.log(this.imageUrl)
          this.listPayUser.invoices = [...this.listPayUser.invoices,
          {
            fileId: null,
            fileName: fileU,
            fileUrlMark: mark,
            delFile: 0
          }]
          Toast('上传成功');
          console.log(this.listPayUser.invoices)
        }).catch(err => {
          Toast('上传失败');
        })
      })

    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (event) {
          resolve(event)
        }
      })
    },
    toBlob(urlData, fileType) {
      let bytes = window.atob(urlData);
      let n = bytes.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bytes.charCodeAt(n);
      }
      return new Blob([u8arr], { type: fileType });
    },
    deleteA(e) {
      console.log(e)
      this.listPayUser.invoices.forEach((item, index) => {
        if (e.url && item.fileUrl == e.url) {
          console.log(item)
          item.delFile = 1
        }
        if (e.url == undefined) {
          if (e.file.name && item.fileName == e.file.name) {
            this.listPayUser.invoices.splice(index, 1)
          }
        }
      })
    },
    // 新增报销明细
    newDetail() {
      let ExpReiDetailVOItem = {
        detailId: '',
        makeDay: '',
        makeDate: '',
        makeDateEnd: '',
        feeTypeCollect: '',
        feeType: '',//费用类型第一级
        invCategory: '',//发票类目第二级
        subdivision: '', //细分第三级
        amount: '',
        remark: '',
        delFile: 0, //删除明细,编辑用
      }
      this.listPay.push(ExpReiDetailVOItem)
    },
    // 删除报销明细
    expDelete(itemB, indB) {
      console.log("sahnchu")
      if (this.listPay.length > 1) {
        itemB.delFile = 1
        this.deleteList.push(itemB)
        console.log(this.deleteList)
        this.listPay.splice(indB, 1)
      }
    },
    // 查看历史记录
    viewHistory() {
      this.$router.push('/historyReim')
    },
    // 顶部返回按钮
    onClickLeft() {
      this.$router.push({
        name: 'home',
        query: {
          status: 1
        }
      })
    },
  },
};
</script>
