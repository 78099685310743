import axios from "axios";
//正式
axios.defaults.baseURL = "https://erpapi.zanhua.com.cn";
//测试
// axios.defaults.baseURL = "https://erpapitest.zanhua.com.cn";
axios.interceptors.request.use((config) => {
  config.headers.common["token"] = localStorage.getItem("token");
  // config.headers['token'] = 'BFFF46AB9CE0366362A6B9A06805EA6D';
  return config;
});
//封装getpost请求
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export default axios;
