import { post } from './request-get';
import apis from './api';
// const nowUrl = window.location.href.split('?')[0];

const wx = window.wx;
// type：1发放申请 2费用申请 3报销申请 4员工薪资 5 合同 6开票
const sendWx = (url, extData, thirdNo, func, type) => {
  post(apis.getConfigSignature, { url:url,type:type }).then((r) => {
    // console.log(wx)
    if (r.success) {
      wx.checkJsApi({
        jsApiList: ['config','agentConfig','thirdPartyOpenPage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function(res) {
          // alert(111)
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        }
    });
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: r.resultData.appId, // 必填，企业微信的corpID
        timestamp: r.resultData.timestamp, // 必填，生成签名的时间戳
        nonceStr: r.resultData.nonceStr, // 必填，生成签名的随机串
        signature: r.resultData.configSignature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: [
          'agentConfig',
          'openUserProfile',
          'thirdPartyOpenPage',
          'selectExternalContact',
        ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });

      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ['agentConfig','thirdPartyOpenPage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function(res) {
              // 以键值对的形式返回，可用的api值true，不可用为false
              // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          }
      });
        wx.agentConfig({
          corpid: r.resultData.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: Number(r.resultData.agentId), // 必填，企业微信的应用id
          timestamp: r.resultData.timestamp, // 必填，生成签名的时间戳
          nonceStr: r.resultData.nonceStr, // 必填，生成签名的随机串
          signature: r.resultData.agentSignature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: [
            'agentConfig',
            'openUserProfile',
            'thirdPartyOpenPage',
            'selectExternalContact',
          ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
          success: function (res) {
            wx.invoke(
              'thirdPartyOpenPage',
              {
                oaType: '10001', // String
                templateId: r.resultData.templateId, // String请款单的流程模版
                thirdNo: thirdNo, //请款单的单号 （必须） 用于查询  请款单申请之后会返回
                extData,
              },
              function (res) {
                // alert(JSON.stringify(res));
              }
            );
            // 回调
          },
          fail: function (res) {
            // alert(JSON.stringify(res));
            if (res.errMsg.indexOf('function not exist') > -1) {
              // alert('版本过低请升级');
            }
          },
        });
      });
    }
  });
};

export default sendWx;